import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Button, Grid, Typography } from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import { SAVE_ADMIN_BID_TEMPLATE_REQ } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminTemplate";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_TEMPLATE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_TEMPLATE_QUERY";

function OverwriteTemplateModal({
  showsOverwriteTemplateModal,
  setShowsOverwriteTemplateModal,
  renamedTemplateSavePayload,
  templateName,
}: {
  showsOverwriteTemplateModal: boolean;
  setShowsOverwriteTemplateModal: Dispatch<SetStateAction<boolean>>;
  renamedTemplateSavePayload: SAVE_ADMIN_BID_TEMPLATE_REQ;
  templateName: string;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const {
    mutate: saveAdminBidTemplate,
    isLoading: saveLoading,
    ResponseHandler: ResponseHandlerOfSaveAdminBidTemplate,
  } = ADMIN_TEMPLATE_QUERY.useSaveAdminBidTemplate();

  const overwriteTemplateSavePayload = useMemo(() => {
    return { ...renamedTemplateSavePayload, saveStatus: "overwrite" };
  }, [renamedTemplateSavePayload]);

  const handleTemplateOverwrite = useCallback(() => {
    saveAdminBidTemplate(overwriteTemplateSavePayload, {
      onSuccess: () => {
        handleSnackbarOpen("요청에 성공했습니다.");
        setShowsOverwriteTemplateModal(false);
      },

      onError: () => {
        handleSnackbarOpen("요청에 실패했습니다.", "error");
      },
    });
  }, [
    handleSnackbarOpen,
    overwriteTemplateSavePayload,
    saveAdminBidTemplate,
    setShowsOverwriteTemplateModal,
  ]);

  const handleModalClose = useCallback(() => {
    setShowsOverwriteTemplateModal(false);
  }, [setShowsOverwriteTemplateModal]);

  const ModalBody = useMemo(() => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
            이름이{templateName}인 템플릿이 이미 있습니다.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
            탬플릿 내용을 덮어쓰시겠습니까?
          </Typography>
        </Grid>

        <Grid item container xs={12} spacing={2} justifyContent="center">
          <Grid item>
            <Button
              onClick={handleModalClose}
              variant="contained"
              color="primary"
            >
              돌아가기
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              color="success"
              onClick={handleTemplateOverwrite}
              disabled={saveLoading}
            >
              덮어쓰기
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [handleModalClose, handleTemplateOverwrite, saveLoading, templateName]);

  return (
    <>
      <Modal
        isOpened={showsOverwriteTemplateModal}
        handleClose={handleModalClose}
        modalBody={ModalBody}
      />

      {ResponseHandlerOfSaveAdminBidTemplate}
    </>
  );
}

export default OverwriteTemplateModal;
