import { useState } from "react";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import withRequireAuth from "@sellernote/_shared/src/hocs/withRequireAuth";
import useFileUrl from "@sellernote/_shared/src/hooks/admin/useFileUrl";
import { BidUploadDataType } from "@sellernote/_shared/src/types/common/upload";

import FileViewer from "./FileViewer";
import FileViewerController from "./FileViewerController";

function NewWindowOpenFilePreview() {
  const s3Url = sessionStorage.getItem("s3Url") || "";
  const fileExtension = sessionStorage.getItem("fileExtension") || "";
  const fileName = sessionStorage.getItem("fileName") || "";
  const dataType = sessionStorage.getItem("dataType");
  const attachmentId = sessionStorage.getItem("attachmentId") || "";

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfScale, setPdfScale] = useState(1.0);
  const [imageWidth, setImageWidth] = useState(50);

  const { fileUrl, isPdf, isHwp, hwpRenderDivRef } = useFileUrl(
    s3Url,
    fileExtension
  );

  const handleDocumentLoadSuccess = ({
    numPages,
  }: {
    numPages: number | null;
  }) => {
    setNumPages(numPages);
  };

  return (
    <>
      {dataType && (
        <FileViewerController
          fileName={fileName}
          s3Url={s3Url}
          fileExtension={fileExtension}
          isPdf={isPdf}
          isHwp={isHwp}
          setPageNumber={setPageNumber}
          setImageWidth={setImageWidth}
          imageWidth={imageWidth}
          pdfScale={pdfScale}
          setPdfScale={setPdfScale}
          pageNumber={pageNumber}
          numPages={numPages}
          dataType={dataType as BidUploadDataType}
          attachmentId={Number(attachmentId)}
          isNewWindow={true}
        />
      )}

      <FileViewer
        isHwp={isHwp}
        isPdf={isPdf}
        fileExtension={fileExtension}
        imageWidth={imageWidth}
        fileUrl={fileUrl}
        hwpRenderDivRef={hwpRenderDivRef}
        handleDocumentLoadSuccess={handleDocumentLoadSuccess}
        pdfScale={pdfScale}
        pageNumber={pageNumber}
      />
    </>
  );
}

export default withRequireAuth(NewWindowOpenFilePreview);
