import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { Button, IconButton, Tooltip, Typography } from "@mui/material";
import { yellow } from "@mui/material/colors";

import LegacyTable, {
  LegacyTableBodyRow,
} from "@sellernote/_shared/src/admin-ui/components/LegacyTable";
import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import {
  GET_ADMIN_TEMPLATE_DETAIL_RES,
  GET_ADMIN_TEMPLATE_LIST_SEARCH_KIND,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminTemplate";
import {
  ADMIN_BID_FREIGHT_TYPE_OPTION_LIST,
  ADMIN_BID_INCOTERMS_OPTION_LIST,
} from "@sellernote/_shared/src/constants/forwarding/adminBid";
import useCheckTemplateData from "@sellernote/_shared/src/hooks/admin/useCheckTemplateData";
import useGetObjectWithTermSearchTypeKey from "@sellernote/_shared/src/hooks/admin/useGetObjectWithTermSearchTypeKey";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared/src/hooks/admin/useSearchWithTerm";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import useTableHeadFilter from "@sellernote/_shared/src/hooks/admin/useTableHeadFilter";
import useTemplateTableAutoCompletes from "@sellernote/_shared/src/hooks/admin/useTemplateTableAutoCompletes";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import ADMIN_TEMPLATE_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_TEMPLATE_QUERY";
import {
  AdminBidTemplate,
  TemplateCategory,
} from "@sellernote/_shared/src/types/forwarding/adminTemplate";
import { omitWithEllipsis } from "@sellernote/_shared/src/utils/common/string";
import { getChangeTemplateCategoryToKr } from "@sellernote/_shared/src/utils/forwarding/admin/adminTemplate";
import { changePortIdToPortName } from "@sellernote/_shared/src/utils/forwarding/admin/port";

import useForwardingManagerSelect from "./useForwardingManagerSelect";
import useTemplateCategorySelect from "./useTemplateCategorySelect";
import useTemplateTableHeadCells from "./useTemplateTableHeadCells";

type CellId = keyof AdminBidTemplate | "delete";

const termSearchTypeOptions: TermSearchType<GET_ADMIN_TEMPLATE_LIST_SEARCH_KIND>[] =
  [
    {
      label: "템플릿명",
      value: "templateName",
    },

    {
      label: "회사명",
      value: "companyName",
    },

    {
      label: "파트너명",
      value: "partnerName",
    },
  ];

function TemplateTableModal({
  defaultCategory,
  showsTemplateTableModal,
  setShowsTemplateTableModal,
  updateTemplateData,
  setSearchTerm,
  templateType,
}: {
  defaultCategory: TemplateCategory;
  showsTemplateTableModal: boolean;
  setShowsTemplateTableModal: Dispatch<SetStateAction<boolean>>;
  updateTemplateData: ({
    templateDetailData,
    templateListItem,
  }: {
    templateDetailData: GET_ADMIN_TEMPLATE_DETAIL_RES | undefined;
    templateListItem: AdminBidTemplate;
  }) => void;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  templateType: "bidApply" | "withdrawal";
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { FilterPanel: FreightTypeFilterPanel, filter: freightTypeFilterData } =
    useTableHeadFilter({
      filterOptions: ADMIN_BID_FREIGHT_TYPE_OPTION_LIST,
    });

  const { FilterPanel: IncotermsFilterPanel, filter: incotermsFilterData } =
    useTableHeadFilter({
      filterOptions: ADMIN_BID_INCOTERMS_OPTION_LIST,
    });

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      // 견적 템플릿 테이블에서는 파트너명 검색 x
      termSearchTypeOptions: termSearchTypeOptions.filter((v) => {
        if (templateType === "bidApply") {
          return v.label !== "파트너명";
        }
        return v;
      }),
    });

  const { objectWithTermSearchTypeKey } = useGetObjectWithTermSearchTypeKey({
    termSearchType,
    debouncedSearchTerm,
  });

  const { ForwardingManagerSelect, forwardingManagerId } =
    useForwardingManagerSelect();

  const { TemplateCategorySelect, templateCategory } =
    useTemplateCategorySelect({
      defaultCategory,
    });

  const {
    mutate: registerFavoriteTemplate,
    ResponseHandler: ResponseHandlerOfRegisterFavoriteTemplate,
  } = ADMIN_TEMPLATE_QUERY.useRegisterFavoriteTemplate();

  const {
    mutate: deleteFavoriteTemplate,
    ResponseHandler: ResponseHandlerOfDeleteFavoriteTemplate,
  } = ADMIN_TEMPLATE_QUERY.useDeleteFavoriteTemplate();

  const {
    mutate: deleteTemplate,
    ResponseHandler: ResponseHandlerOfDeleteTemplate,
  } = ADMIN_TEMPLATE_QUERY.useDeleteTemplate();

  const { data: countryData } = ADMIN_COMMON_QUERY.useGetCountryList();

  const { TemplateTableAutoCompletes, countryName, polId, podId, portData } =
    useTemplateTableAutoCompletes({ countryData });

  const { data: templateList, refetch: refetchTemplateList } =
    ADMIN_TEMPLATE_QUERY.useGetAdminBidTemplates({
      category: templateCategory,
      freightType: freightTypeFilterData,
      incoterms: incotermsFilterData,
      country: !countryName ? undefined : countryName,
      startPortId: !polId ? undefined : polId,
      endPortId: !podId ? undefined : podId,
      administratorId: !forwardingManagerId ? undefined : forwardingManagerId,
      ...objectWithTermSearchTypeKey,
    });

  const {
    mutate: getAdminBidTemplateDetail,
    ResponseHandler: ResponseHandlerOfGetAdminBidTemplateDetail,
  } = ADMIN_TEMPLATE_QUERY.useGetAdminBidTemplateDetail();

  const { setIsLoadTemplateData, changeIsLoadTemplateToFalseExceptAll } =
    useCheckTemplateData();

  const { applyHeadCells, withdrawalHeadCells } = useTemplateTableHeadCells({
    FreightTypeFilterPanel,
    IncotermsFilterPanel,
  });

  const handleFavoriteTemplateRequestSuccess = useCallback(() => {
    return () => {
      handleSnackbarOpen("요청에 성공했습니다.", "success");
      refetchTemplateList();
    };
  }, [handleSnackbarOpen, refetchTemplateList]);

  const handleFavoriteTemplateRegister = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      templateId: number,
      category: TemplateCategory
    ) => {
      e.stopPropagation();

      registerFavoriteTemplate(
        {
          templateId,
          category,
        },
        {
          onSuccess: handleFavoriteTemplateRequestSuccess(),

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    },
    [
      handleFavoriteTemplateRequestSuccess,
      handleSnackbarOpen,
      registerFavoriteTemplate,
    ]
  );

  const handleFavoriteTemplateDelete = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      favoriteId: number,
      category: TemplateCategory
    ) => {
      e.stopPropagation();

      deleteFavoriteTemplate(
        {
          pathParams: { favoriteId },
          category,
        },
        {
          onSuccess: handleFavoriteTemplateRequestSuccess(),

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    },

    [
      deleteFavoriteTemplate,
      handleFavoriteTemplateRequestSuccess,
      handleSnackbarOpen,
    ]
  );

  const handleTemplateDelete = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      templateId: number,
      category: TemplateCategory
    ) => {
      e.stopPropagation();

      deleteTemplate(
        {
          pathParams: { templateId },
          category,
        },
        {
          onSuccess: handleFavoriteTemplateRequestSuccess(),

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    },

    [deleteTemplate, handleFavoriteTemplateRequestSuccess, handleSnackbarOpen]
  );

  const isWithdrawalTemplate = useMemo(() => {
    if (
      defaultCategory === "all" ||
      defaultCategory === "freightCost" ||
      defaultCategory === "domesticCost" ||
      defaultCategory === "inlandCost" ||
      defaultCategory === "otherCost" ||
      defaultCategory === "tax" ||
      defaultCategory === "localCost"
    ) {
      return false;
    }

    return true;
  }, [defaultCategory]);

  const rows = useMemo(() => {
    if (!templateList) return [];

    const tableRow = templateList.map((template) => {
      const row: LegacyTableBodyRow<CellId> = {
        isFavorite: template.isFavorite ? (
          <IconButton
            onClick={(e) =>
              handleFavoriteTemplateDelete(
                e,
                template.favoriteId,
                template.category
              )
            }
            sx={{ color: yellow[700] }}
            aria-label="add an alarm"
          >
            <StarIcon />
          </IconButton>
        ) : (
          <IconButton
            onClick={(e) => {
              handleFavoriteTemplateRegister(e, template.id, template.category);
            }}
            aria-label="add an alarm"
          >
            <StarOutlineIcon />
          </IconButton>
        ),
        name: (
          <Tooltip title={template.name}>
            <Typography variant="body1" component="div">
              {omitWithEllipsis({
                src: template.name,
                maxLength: 10,
                ellipsis: "...",
              })}
            </Typography>
          </Tooltip>
        ),
        category: getChangeTemplateCategoryToKr(template.category),
        country:
          countryData?.find((v) => {
            return v.name === template.country;
          })?.nameKR || template.country,
        freightType: template.freightType,
        incoterms: template.incoterms,
        startPortId: changePortIdToPortName({
          portData,
          portId: template.startPortId,
        }),
        endPortId: changePortIdToPortName({
          portData,
          portId: template.endPortId,
        }),

        destination: template.destination || "-",
        partnerName:
          templateType === "withdrawal" ? template.partnerName : undefined,
        companyName: template.companyName,
        volume: template.volume,
        administratorName: template.administratorName,
        delete: (
          <Button
            color="error"
            onClick={(e) => {
              handleTemplateDelete(e, template.id, template.category);
            }}
          >
            삭제
          </Button>
        ),
        handleRowClick() {
          getAdminBidTemplateDetail(
            {
              pathParams: { templateId: template.id },
            },
            {
              onSuccess: ({ data }) => {
                setIsLoadTemplateData(defaultCategory, true);
                if (defaultCategory === "all") {
                  changeIsLoadTemplateToFalseExceptAll();
                }

                setSearchTerm(template.name);
                updateTemplateData({
                  templateDetailData: data,
                  templateListItem: template,
                });
                setShowsTemplateTableModal(false);
                handleSnackbarOpen("요청에 성공했습니다.");
              },

              onError: () => {
                handleSnackbarOpen("요청에 실패했습니다.", "error");
              },
            }
          );
        },
      };

      return row;
    });

    // 견적지원일 때는 파트너 항목 제거
    if (templateType === "bidApply") {
      return tableRow.map((row) => {
        const { partnerName, ...newRow } = row;
        return newRow;
      });
    }

    return tableRow;
  }, [
    changeIsLoadTemplateToFalseExceptAll,
    countryData,
    defaultCategory,
    getAdminBidTemplateDetail,
    handleFavoriteTemplateDelete,
    handleFavoriteTemplateRegister,
    handleSnackbarOpen,
    handleTemplateDelete,
    portData,
    setIsLoadTemplateData,
    setSearchTerm,
    setShowsTemplateTableModal,
    templateList,
    templateType,
    updateTemplateData,
  ]);

  return (
    <>
      <Modal
        isOpened={showsTemplateTableModal}
        handleClose={() => setShowsTemplateTableModal(false)}
        modalBody={
          <LegacyTable
            toolbarItems={{
              left: [
                isWithdrawalTemplate && TemplateCategorySelect,
                ForwardingManagerSelect,
                TemplateTableAutoCompletes,
                TermSearchPanel,
              ],
            }}
            title="템플릿 리스트"
            headCells={
              templateType === "withdrawal"
                ? withdrawalHeadCells
                : applyHeadCells
            }
            rows={rows}
          />
        }
      />

      {ResponseHandlerOfRegisterFavoriteTemplate}
      {ResponseHandlerOfDeleteFavoriteTemplate}
      {ResponseHandlerOfDeleteTemplate}
      {ResponseHandlerOfGetAdminBidTemplateDetail}
    </>
  );
}

export default TemplateTableModal;
