import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import VideogameAssetOutlinedIcon from "@mui/icons-material/VideogameAssetOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import Tooltip from "@mui/material/Tooltip";
import { detect } from "detect-browser";
import { useAtomValue, useSetAtom } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  FORWARDING_ADMIN_AUTH_ATOMS,
  FORWARDING_ADMIN_AUTH_SELECTORS,
} from "@sellernote/_shared/src/jotaiStates/auth";

import LayoutForAdmin from "../../components/LayoutForAdmin";

import Styled from "./index.styles";

const browser = detect();

function SupportedBrowserAlert() {
  return browser?.name !== "chrome" ? (
    <Styled.browserAlert
      message="본 서비스는 크롬브라우저에서만 정상 작동합니다"
      type="error"
    />
  ) : null;
}

function LayoutForShipdaAdmin({
  breadcrumbs,
  title,
  children,
}: {
  breadcrumbs?: string[];
  title?: string;
  children: React.ReactNode;
}) {
  const history = useHistory();

  const setLoggedIn = useSetAtom(FORWARDING_ADMIN_AUTH_ATOMS.ADMIN_LOGGED_IN);

  const handleLogout = useCallback(() => {
    window.localStorage.clear();
    setLoggedIn(false);
    window.location.href = "/login";
  }, [setLoggedIn]);

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  return (
    <LayoutForAdmin
      logo={
        <>
          <img src="/images/logo.png" alt="logo" style={{ width: "80px" }} />
          BackOffice
        </>
      }
      headerItems={[
        <div className="name" key={0}>
          {currentAdminAuthInfo?.accountId}
        </div>,
        <Tooltip title="비밀번호 변경" key={1}>
          <VpnKeyOutlinedIcon
            sx={{ color: "#9e9e9e", fontSize: "24px", cursor: "pointer" }}
            onClick={() =>
              history.push(
                `/admin-user/change-password/${currentAdminAuthInfo?.id}`
              )
            }
          />
        </Tooltip>,

        <VideogameAssetOutlinedIcon
          sx={{ color: "#9e9e9e", fontSize: "24px", cursor: "pointer", ml: 1 }}
          key={2}
          onClick={() =>
            window.open("https://geunee92.github.io/my-suika-game/")
          }
        />,
      ]}
      onLogout={handleLogout}
      title={title}
      breadcrumbs={breadcrumbs}
    >
      <SupportedBrowserAlert />

      {children}
    </LayoutForAdmin>
  );
}

function LayoutForPartnerAdmin({
  title,
  breadcrumbs,
  children,
}: {
  title?: string;
  breadcrumbs?: string[];
  children: React.ReactNode;
}) {
  const history = useHistory();

  const setLoggedIn = useSetAtom(
    FORWARDING_ADMIN_AUTH_ATOMS.PARTNER_ADMIN_LOGGED_IN
  );

  const handleLogout = useCallback(() => {
    window.localStorage.clear();
    setLoggedIn(false);
    window.location.href = "/login";
  }, [setLoggedIn]);

  const currentUser = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_PARTNER_ADMIN_AUTH_INFO
  );

  return (
    <LayoutForAdmin
      logo={
        <>
          <img src="/images/logo.png" alt="logo" style={{ width: "80px" }} />
          파트너 Admin
        </>
      }
      headerItems={[
        <div className="name" key={0}>
          {`${currentUser?.name}(${currentUser?.company})`}
        </div>,

        <Tooltip title="비밀번호 변경" key={1}>
          <VpnKeyOutlinedIcon
            sx={{
              color: "#9e9e9e",
              fontSize: "24px",
              cursor: "pointer",
            }}
            onClick={() => {
              history.push(`/change-password`);
            }}
          />
        </Tooltip>,
      ]}
      onLogout={handleLogout}
      title={title}
      breadcrumbs={breadcrumbs}
    >
      <SupportedBrowserAlert />

      {children}
    </LayoutForAdmin>
  );
}

const layoutByAppName = {
  "shipda-admin": LayoutForShipdaAdmin,
  "partner-admin": LayoutForPartnerAdmin,
};

const Layout = layoutByAppName[APP_NAME as "shipda-admin" | "partner-admin"];

export default Layout;
