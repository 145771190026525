import { Button, Grid, Typography } from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";

function BeforeUnloadModal({
  handleBeforeUnloadModalClose,
  showsBeforeUnloadModal,
  handleFilePreviewClose,
}: {
  handleBeforeUnloadModalClose: () => void;
  showsBeforeUnloadModal: boolean;
  handleFilePreviewClose: () => void;
}) {
  return (
    <Modal
      handleClose={handleBeforeUnloadModalClose}
      isOpened={showsBeforeUnloadModal}
      modalBody={
        <>
          <Typography variant="h6" component="div">
            파일 설명 변경이 저장되지 않았습니다.
          </Typography>

          <Typography variant="h6" component="div">
            파일 미리보기를 나가시겠습니까?
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: "16px" }}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilePreviewClose}
              >
                예
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                variant="contained"
                color="success"
                onClick={handleBeforeUnloadModalClose}
              >
                아니오
              </Button>
            </Grid>
          </Grid>
        </>
      }
    />
  );
}

export default BeforeUnloadModal;
